// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
    CarIcon, CoinsIcon, InstantPaymentIcon,
    // TinyArrowLeftIcon 
} from "../../../assets/icons";
import SectionBg3 from "../../../assets/imgs/section-bg-3.svg";
import SectionBg4 from "../../../assets/imgs/section-bg-4.svg";
import SectionBg5 from "../../../assets/imgs/section-bg-5.svg";
import SectionBg6 from "../../../assets/imgs/section-bg-6.svg";
import SectionBg7 from "../../../assets/imgs/section-bg-7.svg";
import { FadeUpFramer } from "../../../shared/components/custom-reveal-animations/custom-reveal-animations.ui";

const IndividualOffersSection = () => {
    const { t } = useTranslation();

    const cardsList = [
        {
            icon: <CarIcon color="#FF9800" />,
            title: "vehicleLeasing",
            details: "vehicleLeasingDetailsIndividual",
            url: "https://wa.me/+966576030983",
            sectionImg: <img className="hide-sm section-bg" src={SectionBg6} alt="Section Background" />,
        },
        {
            icon: <InstantPaymentIcon color="#FF9800" />,
            title: "instantPayment",
            details: "instantPaymentDetails",
            url: "https://wa.me/+966576030983",
        },
        {
            icon: <CoinsIcon color="#FF9800" />,
            title: "workWithUber",
            details: "workWithUberDetails",
            url: "https://wa.me/+966576030983",
            sectionImg: <img className="hide-sm section-bg" src={SectionBg7} alt="Section Background" />,
        },
    ];
    return (
        <section className="individual-offers-section">
            <div className="screen-wrapper">
                <div className="title-wrapper">
                    <FadeUpFramer>
                        <h3 className="title">{t("whatOfferIndividual")}</h3>
                        <p className="details">{t("whatOfferIndividualDetails")}</p>
                    </FadeUpFramer>
                </div>
                <ul className="cards-wrapper">
                    {cardsList.map((card, index) => (
                        <li key={index}>
                            <FadeUpFramer>
                                <div className="card">
                                    {card.icon}
                                    <h5 className="title">{t(card.title)}</h5>
                                    <p className="details">{t(card.details)}</p>
                                    {/* <Link className="explore-btn" to={card.url}>
                                        {t("exploreMore")} <TinyArrowLeftIcon />
                                    </Link> */}
                                    {card.sectionImg ? card.sectionImg : <></>}
                                </div>
                            </FadeUpFramer>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="floating-img-wrapper">
                <img className="section-bg-3" src={SectionBg3} alt="Section Background" />
                <img className="section-bg-4" src={SectionBg4} alt="Section Background" />
                <img className="section-bg-5" src={SectionBg5} alt="Section Background" />
            </div>
        </section>
    );
};

export default IndividualOffersSection;
